import React from 'react'

const About = () => {
  return (
    <div className='about'>
      <article className='about__body'>
        <figure>
          <img className='about__stairs' src='https://i.imgur.com/CtmOjDJ.jpg'
            alt='Me on a staircase' width='400'/>
            <figcaption className='center opacity_'>Me :3</figcaption>
        </figure>
        <p>My name is Edgar Ubaldo and I studied in the Department of ICS at UCI for 5 years. I grew up loving video games and it's part of what got me interested in computers. At the time, I really liked games like Minecraft and Counter Strike. When it came to choosing what I wanted to study, I decided pretty early on it would be something related to computers. Nowadays, I like so many things and I'm excited to try new stuff.</p>
        <br></br>
        <p>Goodluck to everyone!</p>
      </article>
    </div>
  )
}

export default About