import './App.css';
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/layout';
import Home from './components/home';
import Projects from './components/projects';
import Unreal from './components/unreal';
import About from './components/about';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path= '/' element={<Layout />} >
            <Route index element={<Home />} />
            <Route path="projects" element={<Projects />} />
            <Route path="unreal" element={<Unreal />} />
            <Route path="about" element={<About />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
