import React from 'react'
import Tag from './tags';
//import Author from './author';

const ProjectNode = ({id, name, description, tags, author, preview, url }) => {
    
    const renderTags = () => {
        //console.log(tags);
        return tags.map((t, index) => (
            <Tag tag={t} key= {index} />
        ));
    }; 

    const openInTab = (myurl) => {
        window.open(myurl, '_blank', 'noreferrer');
    };

    return (
        <div className="nodeContainer" onClick={()=> openInTab(url)}>
            <img className="nodeImg" src={preview} alt="bruh" width='300' />
            <section className='projectCell'>
                <h4 className='nodeTitle'>{name}</h4>        
                <p className='projectCell__authors'>By: {author.join(', ')}</p>
                
                <article className='projectCell__desc'>{description.substring(0,200)}{description.length >= 200 && '...'}</article>
                <section className='cellTags'>
                    Tags:{renderTags()} 
                </section>
            </section>
        </ div>
  );
};

export default ProjectNode