import React from 'react'
import { Link, Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <>
        <header className='headerLayout'>
           
            
            <nav className='header__menu'>
                <Link to='/'>
                    <p>HOME</p>
                </Link>
                <Link to='/projects'>
                    <p>PROJECTS</p>
                </Link>
                <Link to='/unreal'>
                    <p>UE5</p>
                </Link>
                <Link to='/about'>
                    <p>ABOUT</p>
                </Link>
            </nav>
        </header>
        <main>
            <Outlet />
        </main>

    </>
  )
}

export default Layout