import React from 'react'


const Home = () => {
  return (
    <>
        <article className='homescreen'>
            <section className="homescreen__title">
                <h1 className='header__subtext'>HI, MY NAME IS EDGAR</h1>
                <h2 className='header__text'>Welcome to My Site!</h2>

                <div className="wrapper">
                  <div className="static-text">I am</div>
                  <ul className="dynamic-text">
                    <li><span>a Student</span></li>
                    <li><span>a Programmer</span></li>
                    <li><span>sometimes Dumb</span></li>
                    <li><span>very Lucky</span></li>
                  </ul>
                </div>
            </section>
            <img className='homescreen__img' src={process.env.PUBLIC_URL + '/img/20210602_134722.jpg'} alt="Me at the Park :3" width="400" height='600' />
        </article>
    </>
  )
}

export default Home