import React from 'react'
import ProjectNode from './projectNode'
import Records from '../db/projects.json'
//import Filter from './filter'
import CaretIcon from '../icons/1916330-200.png'
import { useEffect } from 'react'


const Projects = () => {

    const [filters, setFilters] = React.useState({
        "Python": false,  
        "React":false, 
        "CSS":false , 
        "JavaScript":false,
        "Groupwork":false,
        "Solo":false
    });
    const [isOpen, setIsOpen] = React.useState(false);
    const [myProjects, setMyProjects] = React.useState(Records.projects);

    let menuRef = React.useRef();

    useEffect(() => {
        let handler = (e) =>{
            if(!menuRef.current.contains(e.target)){
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handler);

        return() => {
            document.removeEventListener("mousedown", handler);
        }
    })

    function toggleSidebar() {
        setIsOpen(!isOpen);
    }

    const resetFilters = async () => {
        console.log('old filters: ', filters);
        const keys = Object.keys(filters);

        const newFilters = keys.reduce((obj, key) => {
            obj[key] = false;
            return obj;
        }, {});

        

        await setFilters(newFilters);
        setMyProjects(Records.projects);
        //toggleSidebar();
    }


    const retrieveProjects = () =>{
        
        const allTagsDisabled = !Object.values(filters).some(value => value === true);
        if (!allTagsDisabled) {
            const activeFilters = Object.keys(filters).filter(filter => filters[filter]);

            const filteredProjects = Records.projects.filter(project => {
                return activeFilters.every(filter => project.tags.includes(filter));
            });
            //console.log('test1', filteredProjects);
            setMyProjects(filteredProjects);
        }
        else
            setMyProjects(Records.projects);
        
    }

    
    
    function handleFilterChange(event) {
        const { name, checked } = event.target;
        
        setFilters({ ...filters, [name]: checked });
        //console.log(filters);
    } 

    function handleApply() {
        retrieveProjects();
        toggleSidebar();
        //console.log('do something...');
    }


    const renderProjects = () => {
        //console.log(Records);
        return myProjects.map( record => (
            <ProjectNode  key={record.id} {...record} />
        ));
    }



    return (
        <>
            <div className='menuContainer' ref={menuRef}>
                <img className='filterIcon' src={CaretIcon} width='40' alt='Filters' onClick={toggleSidebar}/>
                <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
                    <form className='tagFilters'>
                        <img className='filterCloseIcon' src={CaretIcon} width='40' alt='Filters' onClick={toggleSidebar}/>
                        <h4>Display Projects That Match Any Tag:</h4>
                        <label>
                        <input
                            type="checkbox"
                            name="Python"
                            value='Python'
                            checked= {filters.Python}
                            onChange={handleFilterChange}
                        />
                        Python
                        </label>

                        <label>
                        <input
                            type="checkbox"
                            name="React"
                            value='React'
                            checked= {filters.React}
                            onChange={handleFilterChange}
                        />
                        React
                        </label>

                        <label>
                        <input
                            type="checkbox"
                            name="CSS"
                            value='CSS'
                            checked= {filters.CSS}
                            onChange={handleFilterChange}
                        />
                        CSS
                        </label>

                        <label>
                        <input
                            type="checkbox"
                            name="JavaScript"
                            value='JavaScript'
                            checked= {filters.JavaScript}
                            onChange={handleFilterChange}
                        />
                        JavaScript
                        </label>

                        <label>
                        <input
                            type="checkbox"
                            name="Groupwork"
                            value='Groupwork'
                            checked= {filters.Groupwork}
                            onChange={handleFilterChange}
                        />
                        Groupwork
                        </label>

                        <label>
                        <input
                            type="checkbox"
                            name="Solo"
                            value='Solo'
                            checked= {filters.Solo}
                            onChange={handleFilterChange}
                        />
                        Solo
                        </label>

                    </form>
                    <div className='buttonHolder'>
                        <div className='test' onClick={resetFilters}>Clear</div>
                        <div className='test' onClick={handleApply}>Apply</div>
                    </div>
                </div>
            </div>
            <div className='projects'>
                {renderProjects()}
            </ div>
        </>
    )
}

export default Projects