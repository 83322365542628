import React from 'react'

const Unreal = () => {
  return (
    <div className='unreal'>
      <article className='unreal__body'>
        <figure>
          <img src='https://www.mobygames.com/images/shots/l/173619-paper-mario-nintendo-64-screenshot-hit-his-weak-point-for.jpg'
            alt='screen cap of Paper Mario 64' width='400'/>
            <figcaption className='center opacity_'>source: Mobygames</figcaption>
        </figure>
        
        <p>
          <b>Goal</b>: My goal is to create a game that takes heavy inspiration from Paper Mario 64 (2000). A 2.5D JRPG style game with a turn-based combat and buddy system. Paper Mario was an incredibly fun and enjoyable game whereas I found other JRPG’s to fall short. It stood out in its category with its unique paper style and fun environments. As a person who prefers to play on PC, there is a lack of games that satisfy this itch that I got from the Paper Mario game. 
        </p>

        <p>
          <b>Starting</b>: Never having developed games before, I decided to try using Unreal Engine 5 as my choice to begin exploring the idea.Unreal Engine is an amazing tool to anyone who is interested in making games, scenes, etc. After watching a long tutorial to learn the very basics of UE5, I began trying to recreate the look of the Paper Mario 64. If I can make something that looks like it, then it would begin to feel real…
        </p>

        <figure>
          <img src='https://i.imgur.com/xbSuuRV.gif' alt='My Paper Mario Demo' width='400' />
          <figcaption className='center opacity_'>My Paper Mario Demo</figcaption>
        </figure>

        <p>
          This is what I was able to create after several hours of tutorials and playing around with Unreal. Most of the 2D assets I used to create my very simple demo were made/drawn by me (with the exception of the mario sprite/animations). The paper mario sprite is just a placeholder to help visualize the movement and ambition of the project. I would say that it looks pretty damn good visually. 
        </p>

        <p>
          <b>Why Unreal and Inspirations</b>: So I constantly read people recommending unity for 2d game creation and I could see why. Games like cuphead, and a plethora of JRPG’s were made with unity. One game that stands out is Bug Fables: The Everlasting Sapling, made in Unity. 
        </p>

        <figure>
          <img src='https://static1.thegamerimages.com/wordpress/wp-content/uploads/2019/11/bug-fables-title.jpg' 
            alt='A screencap of the game Bug Fables' width='400' />
          <figcaption className='center opacity_'>Source: TheGamerImages</figcaption>
        </figure>

        <p>
          It is one of the few games out there that took up the mantle to create a game with the paper aesthetic and took heavy inspirations from the Paper Mario series. There is also a game by the name of The OutBound Ghost that takes a more aesthetic and serious approach to the paper art style and gameplay. 
        </p>

        <figure>
          <img src="https://www.nintenderos.com/wp-content/uploads/2021/06/The-Outbound-Ghost.jpg" 
            alt="A screencap of The Outbound Ghost" width='400' />
            <figcaption className='center opacity_'>Source: Nintenderos</figcaption>
        </figure>

        <p>
          It is very exciting to see people apply their own ideas and narratives to the paper artstyle and gameplay. It is helpful for me to have more games and Developer blog posts to take inspiration and advice from. Hearing the thought process and reasons certain things were done the way they were allows me to be on the lookout for certain pitfalls or directions I can take when developing my own take on this game style. 
        </p>

        <p>
          Ultimately, I chose to use Unreal Engine because I know more about the things that Unreal can do! The team behind Unreal has been doing an incredible job at updating and maintaining their engine. It is incredibly accessible and the cherry on top is that it is free! Not only that, but we have a huge library of free assets to use. Seeing how my game is going to be 2.5d, I believe that Unreal’s shortcomings in 2d development are more than made up for in what we can do in 3d! Like seriously, if you take a few minutes to look into the incredible things people have made using Unreal, you will be mind blown! 
        </p>

        <p>
          <b>Things to do</b>: Before I go crazy and invest hours of time into art, character design, and the narrative of my game, I need to be able to recreate the core gameplay mechanics, from combat mechanics to camera movement. 
        </p>
        
        <p>A few things that I want to learn to start are:</p>

        <ul>
          <li>Implementing a battle event/state</li>
          <li>Implementing platform snapping camera system</li>
          <li>Learning how to do dialogue</li>
          <li>UI</li>
          <li>Levels</li>
          <li>NPCs</li>
        </ul>

        <p>I will update this page as I go on!</p>
        
      </article>
    </div>
  )
}

export default Unreal